.Laser_items_card_outer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Laser_items_card {
  transition: 0.5s;
  display: flex;
  align-items: center;
  gap: 5%;
  width: 80%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  cursor: pointer;
  flex: none;
  order: 0;
  align-self: stretch;
  overflow: hidden;
  margin-bottom: 2%;
}
.Laser_items_card:hover {
  background-color: rgb(255, 245, 220);
}
.Laser_items_card_expand {
  transition: 0.5s;
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 5%;
  width: 80%;
  background: #fffdfd;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  cursor: pointer;
  flex: none;
  order: 0;
  align-self: stretch;
  margin-bottom: 2%;
  /* background-color: yellowgreen; */
}
.Laser_items_card_expand:hover {
  background-color: rgb(255, 245, 220);
}

.items_card_image {
  width: 15%;
  margin: 0;
  padding: 0;
  position: relative;
}
.product_img {
  width: 100%;
  height: 100%;
}
.items_card_image p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  position: absolute;

  height: 80px;

  top: calc(50% - 80px / 2 + 0.5px);

  background: rgba(254, 0, 0, 0.1);
}
.disponsibleContainerDiv{
  display: flex;
  flex-direction: row;
}
.productAvailable{
  display: flex;
  align-items: center;
  height: 25px;
}
.disponsible{
  color: #47B45F;
  font-size: small;
  text-transform: capitalize;
  font-family: "Poppins";
  font-weight: 600;
  /* padding-bottom: 5px; */
}

.greenDotContainer{
  /* height: 12px; */
  width: 12px;
  margin-right: 8px;
}
.greenDotContainer img {
  width: 100%;
  height: 100%;
}
.vendu {
  top: 40%;
  width: 100%;
  position: absolute;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #fe0000;
  padding: 0px !important;
}
.items_card_text {
  width: 65%;
  padding: 3% 0 3% 0;
}

.items_card_text_price {
  display: flex;
  line-height: 30px;
  align-items: center;
  word-wrap: break-word;
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #b3881d;
}

.items_card_text_heading {
  width: 100%;
  word-wrap: break-word;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #2b2926;
  padding: 1% 0 0.5% 0;
}
.items_card_text_description {
  width: 100%;
  word-wrap: break-word;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  padding: 0.5% 0 1% 0;
  color: #6a6a6a;
  order: 2;
  align-self: stretch;
}
.items_card_text_date {
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #02225e;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.date_format {
  margin: 0 1% 0.5% 1%;
}
.items_card_share_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  order: 0;
  color: #b3881d;
}

.items_card_share_icon img:hover {
  background: #ffefcb;
}
.items_card_share_icon:hover {
  background: #ffefcb;
  transition: 0.5s;
}
.items_card_description {
  width: 100%;
  word-wrap: break-word;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #919b9b;
  flex: none;
  order: 0;
  align-self: stretch;
  padding: 1% 0 0 0;
}

.items_card_telephone {
  padding-top: 2%;
  display: flex;
}
/* .items_card_Year {
  padding-top: 2%;
  display: flex;
} */
.items_card_description div {
  word-wrap: break-word;
}
.items_card_description p {
  margin-left: 1%;
  color: #b3881d;
}
a {
  color: #b3881d;
}
@media only screen and (max-width: 500px) {
  /* For mobile phones: */

  .Laser_items_card {
    /* background-color: #2b2926; */
    gap: 2%;
    width: 90%;
    border-radius: 20px;
  }

  .Laser_items_card_expand {
    gap: 2%;
    border-radius: 20px;
    width: 90%;
  }

  .items_card_text_price {
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
  }

  .items_card_text_heading {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
  }
  .items_card_text_description {
    font-weight: 500;
    font-size: 11px;
    line-height: 120%;
  }
  .items_card_text_date {
    font-size: 12px;
  }

  .date_format {
    margin: 0 1% 0.5% 1%;
  }

  .items_card_description {
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
  }

  .items_card_telephone {
    padding-top: 2%;
    display: flex;
  }

  .items_card_description div {
    word-wrap: break-word;
  }
  .items_card_image {
    width: 25%;
    /* background-color: #b3881d; */
  }

  .items_card_share_icon_mui {
    margin-right: 5%;
  }
  .vendu {
    font-size: 16px;
  }
  .items_card_image p {
    top: 25%;
    /* left: 10%; */
    width: 100%;
    height: 50px;
  }
  .disponsible{
    font-size:xx-small;
    font-weight: 600;
    /* padding-bottom: 5px; */
  }
  
  .greenDotContainer{
    /* height: 12px; */
    width: 8px;
    margin-right: 5px;
    margin-bottom: 4px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 1000px) {
  /* For mobile phones: */
  .Laser_items_card {
    width: 90%;
    border-radius: 20px;
  }

  .Laser_items_card_expand {
    border-radius: 20px;
    width: 90%;
  }

  .items_card_text_price {
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
  }

  .items_card_text_heading {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
  }
  .items_card_text_description {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
  }
  .items_card_text_date {
    font-size: 12px;
  }

  .date_format {
    margin: 0 1% 0.5% 1%;
  }

  .items_card_description {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }

  .items_card_telephone {
    padding-top: 2%;
    display: flex;
  }
  /* .items_card_Year {
    padding-top: 2%;
    display: flex;
  } */
  .items_card_description div {
    word-wrap: break-word;
  }
  .vendu {
    font-size: 18px;
  }
  .items_card_image p {
    top: 30%;
    /* left: 10%; */
    width: 100%;
    height: 50px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1600px) {
  .Laser_items_card_outer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .Laser_items_card {
    width: 80%;
    border-radius: 20px;
  }

  .Laser_items_card_expand {
    border-radius: 20px;
    width: 80%;
  }

  .items_card_text_price {
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
  }

  .items_card_text_heading {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
  }
  .items_card_text_description {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
  }
  .items_card_text_date {
    font-size: 12px;
  }

  .date_format {
    margin: 0 1% 0.5% 1%;
  }

  .items_card_description {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }

  .items_card_telephone {
    padding-top: 2%;
    display: flex;
  }
  /* .items_card_Year {
      padding-top: 2%;
      display: flex;
    } */
  .items_card_description div {
    word-wrap: break-word;
  }
  .vendu {
    top: 40%;
    width: 100%;
    position: absolute;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 48px;
    color: #fe0000;
  }
  .items_card_image p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    position: absolute;

    height: 70px;

    top: calc(50% - 80px / 2 + 0.5px);

    background: rgba(254, 0, 0, 0.1);
  }
}
