.home_btn_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fdfcfc;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 100px;
  position: relative;
  width: 100%;
  margin-top: 5%;
  padding: 1% 0;
  /* background-color: #3058a1; */
}
.home_btn_div_inner {
  display: flex;
  /* justify-content: space-between; */

  border-radius: 100px;
  width: 70%;
  /* background-color: #bd2a2a; */
}
.home_btn_div_inner_img {
  display: flex;
  justify-content: center;
  width: 20%;
}
.home_btn_div_inner_img img {
  width: 30%;
  margin-left: 25%;
}
.home_btn_div_inner input {
  background: transparent;
  /* opacity: 0.7; */
  font-size: 25px;
  border: none;
  /* background-color: #32a332; */
  width: 80%;
  padding: 4%;
  outline: none;
}
.home_btn {
  padding: 4%;
  /* padding: 10px 20px; */
  height: 100%;
  margin-right: 1%;
  background: #02225e;
  border-radius: 100px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  border: none;

  cursor: pointer;
}

/* MEDIA QUERY FOR Small DEVICES */

@media screen and (min-width: 320px) and (max-width: 500px) {
  .home_btn_div_inner {
    padding: 1;
    /* background-color: #02225e; */
  }
  .home_btn_div_inner input {
    font-size: medium;
    padding: 1%;
  }
  .home_btn_div_inner img {
    width: 16px;
  }
  .home_btn {
    padding: 12px 23px;
    font-weight: 400;
    font-size: 14px;
  }
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .home_btn_div_inner {
    padding: 1%;
  }
  .home_btn_div_inner input {
    font-size: medium;
    padding: 2%;
  }
  .home_btn_div_inner img {
    width: 16px;
  }

  .home_btn {
    padding: 12px 23px;
    font-weight: 400;
    font-size: 16px;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1600px) {
  .home_btn_div {
    padding: 1% 0;
  }
  .home_btn_div_inner input {
    font-size: medium;
  }
  .home_btn {
    font-size: medium;
    padding: 12px 23px;
  }
  .home_btn_div_inner img {
    width: 16px;
  }
}
