.single_service_container {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20%;
  /* margin-top: 10%; */
  padding-top: 4%;
  padding-left: 10%;
  /* background-color: #8de465; */
}

.single_service_container_reverse {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  /* padding: 1%; */
  padding-left: 10%;
  padding-top: 6%;
  /* background-color: #490808; */
}

.single_service_left {
  flex: 0.5;
  justify-self: flex-start;
  /* background-color: #9c0707; */
  margin-bottom: 12px;
}
.single_service_left img {
  width: 20%;
}
.single_service_left_reverse {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  /* margin-right: 11.5%; */
  flex: 0.5;
  /* background-color: #025e07; */
  /* width: 10rem; */
  width: 20%;
}
.colortxtPartLaser {
  color: #02225e;
  margin-right: 5px;
}
.colortxtPartClient {
  color: #fb8731;
}
.clientTestirowDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: max-content;
  position: relative;
}
.imgThumbDiv {
  position: absolute;
  width: 80px;
  /* right: 70%; */
  left: 41%;
  top: 18%;
  /* height: 80px; */
}
.imgThumbDiv img {
  width: 100%;
}
.single_service_left_reverse img {
  width: 50%;
  /* background-color: #919b9b; */
}
.single_service_right {
  display: flex;
  flex-direction: column;
  margin-right: 11.5%;
  /* width: 7%; */
  flex: 1;
  /* background-color: #e285ce; */
}

.single_service_icon_div {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: -2%;
}
.single_servise_controler_div {
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: auto;
}
.single_service_title {
  font-weight: 600;
  font-size: 30px;
  color: #02225e;
  margin-bottom: 35px;
  margin-left: 2%;
}

.single_service_text_p {
  width: 90%;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: #919b9b;
  text-align: justify;
  width: inherit;
}
#singleDesTwo{
  margin-top: 5px;
}
.single_service_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17px 32px;
  gap: 10px;
  width: fit-content;
  background: #02225e;
  border-radius: 100px;
  border: none;
  font-size: 16px;
  color: #fdfcfc;
  cursor: pointer;
  margin-top: 34px;
  margin-bottom: 90px;
}
.btnWrapDiv1 {
  display: flex;
  justify-content: center;
  /* background-color: #02225e; */
  padding: 0%;
}
.paddingSecSeperate{
  height: 0rem !important;
  width: 100% !important;
  z-index: -1111111 !important;

}
.single_service_btn12 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17px 32px;
  gap: 10px;
  width: fit-content;
  background: #02225e;
  border-radius: 100px;
  border: none;
  font-size: 16px;
  color: #fdfcfc;
  cursor: pointer;
  margin-top: 34px;
  margin-bottom: 3rem;
}
.single_service_left_reverse_mobile {
  display: none;
}
.single_service_left_mobile {
  display: none;
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .single_service_left_reverse_mobile {
    display: flex;
    margin: 0% 5%;
    padding: 0% 0%;
  }
  .imgThumbDiv {
    left: 42%;
    top: 2%;
  }
  .single_service_left_reverse_mobile img {
    display: flex !important;
    justify-content: center !important;
    width: 80% !important;
    height: auto !important;
    padding: 0;
    /* margin: 0; */
    margin-right: 5% !important;
    margin: auto;

  }
  .single_service_left_mobile {
    display: flex;
    height: auto;
    margin: 0% 5% !important;
    padding: 0% 0% !important;
  }
  .single_service_left_mobile img {
    width: 70% !important;
    margin: auto !important;
    height: auto !important;
    padding: 0;
  }
  .btnWrapDiv1{
  height: 10rem;

  }

  .single_service_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2%;
    padding: 0%;
  }

  .single_service_container_reverse {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0% 0;
  }

  .single_service_left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0%;
  }
  .single_service_left img {
    display: none;
    margin: 5% 0%;
    width: 80% !important;
    height: auto !important;
    margin-right: 0 !important;
  }
  .single_service_left_reverse {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 0%;
    margin: 0%;
    padding: 0%;
  }
  .single_service_left_reverse img {
    display: none;
    margin: 5% 0;
    width: 80% !important;
    height: 50% !important;
    margin-right: 0 !important;
  }
  .single_service_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: center;
    margin-right: 0%;
    width: 100%;
  }
  .single_service_right button {
    justify-self: center;
    align-self: center;
    margin-bottom: 70px;
  }

  .single_servise_controler_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    padding-top: 1%;
    width: 90%;
    margin: auto;
   height: 5.2rem;
  }
  .single_service_icon_div {
    width: 40px;
    height: 40px;
    margin-right: 3% !important;
    margin-left: 3% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0;
    padding: 0;
  }

  .single_service_title {
    font-weight: 600;
    font-size: 18px;
    color: #02225e;
  }

  .single_service_text_p {
    margin-left: 0%;
    text-align: left;
    width: 100%;
    padding: 5%;
    padding: 0% 5% 1.5% 5%;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #919b9b;
    text-align: justify;
  }

  .single_service_btn {
    /* padding: 0; */
    /* width: 40%; */
    height: 45px;
    font-size: 13px;
  }
  .single_service_btn12 {
    height: 45px;
    font-size: 13px;
  }
  .link {
    margin: auto;
  }
}
@media only screen and (min-width: 501px) and (max-width: 1000px) {
  .single_service_left_reverse_mobile {
    display: flex;
    margin: 0% 5%;
    padding: 0% 5% 5% 5%;
    /* background-color: #10df1a; */
  }
  .single_service_left_reverse_mobile img {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    /* background-color: #7c5858; */
  }
  .imgThumbDiv {
    top: -9%;
    left: 43%;
  }
  .single_service_left_mobile {
    display: flex;
    margin: 0% 5%;
    padding: 0% 5% 5% 5%;
  }
  .single_service_left_mobile img {
    width: 100% !important;
  }

  .single_service_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2%;
    padding-top: 2%;
    padding-left: 0%;
    margin-bottom: 10%;
  }

  .single_service_container_reverse {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-bottom: 5%;
  }

  .single_service_left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 10% 0;
    /* flex: 1; */
    width: 100%;
    display: none;
  }

  .single_service_left_reverse {
    width: 100%;
    margin-right: 0%;
  }
  .single_service_left_reverse img {
    margin-bottom: 5%;
    width: 60% !important;
    margin-right: 0 !important;
    padding: 10% 0;
    display: none;
  }
  .single_service_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0%;
  }
  .single_servise_controler {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .single_service_left img {
    display: none;
    margin: 5% 0%;
    width: 6 0% !important;
    height: 50% !important;
    margin-right: 0 !important;
  }
  .single_servise_controler_div {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: baseline;

  }

  .single_service_title {
    margin-left: 4%;
    font-weight: 600;
    font-size: 24px;
  }

  .single_service_text_p {
    margin-left: 0%;
    text-align: left;
    width: 100%;
    padding: 5%;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #919b9b;
  }

  .single_service_btn {
    height: 50px;
    margin: 4% 0 0 0;
  }
  .single_service_btn12 {
    height: 50px;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1400px) {
  .paddingSecSeperate{
    height: 4rem !important;
  }
  .single_service_container {
    gap: 2%;
  }
  .imgThumbDiv {
    left: 36%;
  }
  .single_service_left_reverse {
    margin-right: 0%;
  }
  .single_service_left_reverse img {
    width: 50%;
    margin-left: 30%;
  }
  .single_service_right {
    margin-right: 0%;
  }

  .single_service_text_p {
    width: 90%;
    font-size: medium;
    /* background-color: #02225e; */
  }
  .link {
    width: fit-content;
    height: 0;
    background-color: #919b9b;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1700px) {
  .imgThumbDiv {
    left: 33%;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .imgThumbDiv {
    left: 30%;
  }
}
@media only screen and (min-width: 1700px) and (max-width: 2200px) {
  .imgThumbDiv {
    left: 39%;
  }
}
