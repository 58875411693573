.slide_show {
  overflow: hidden;
  max-width: 458px;
}

.slide_show_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.slide_show_Slider {
  white-space: nowrap;
}

.slide {
  display: inline-block;
  height: 478px;
  width: 100%;
  margin: 0 1%;
}

.slide img {
  object-fit: cover;
  width: 95%;
}

/* Buttons */

.slideshowDots {
  text-align: center;
  flex-direction: column;
  display: flex;
  gap: 6px;
}

.slideshowDot {
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 7px 0px;
  background-color: #02225e;
}

.slideshowDot.active {
  background-color: #02225e;
  width: 5px;
  height: 40px;
  border-radius: 100px;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 320px) and (max-width: 500px) {
  /* .slide_show_Slider {
    background-color: black;
    width: ;
  } */

  .slide {
    display: inline-block;

    width: 100%;
    margin: 0 4.8%;
  }
  .slide img {
    width: 70% !important;
    height: 400px !important;
    /* background-color: blue; */
  }
  .slide_show_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
