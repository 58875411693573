/* Importing all CSS files */
@import url('./common/Header.css');
@import url('./common/Footer.css');
@import url('./home/ContactSection.css');
@import url('./home/HeroSection.css');
@import url('./home/TestimonialsSection/Testimonials.css');
@import url('./home/ServicesSection/Index.css');
@import url('./home/ServicesSection/SingleService.css');
@import url('./home/ServicesSection/ServiceImageSlider.css');
@import url('../css/home/ServicesSection/List.css');
@import url('../css/laser/LaserHeroSection.css');
@import url('../css/laser/LaserItemsCard.css');
@import url('../css/laser/LoadMoreButton.css');
@import url('../css/common/InputSearch.css');
@import url('../css/TermsAndCondition//Terms.css');
@import url('../css/Notice//Notice.css');

/*        import Google Fonts       */
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');

/* Defaults */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', 'Josefin Sans', sans-serif;
}

/* Primary Variables */
:root {
  --primary: 'green';
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}
