.services_section_main {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  /* height: 100%; */
  width: 100%;
  margin-top: 50px;
  /* margin-bottom: 5%; */
  position: relative;
  /* background-color: #b3881d; */
}

.services_section_bg {
  height: 100%;
  width: 100%;
}

.services_section_bg_img {
  width: 52%;
  left: 50%;
  top: 0.3%;
  position: absolute;
  opacity: 0.4;
  display: none;
}

.services_section {
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;

  /* background-color: #0e8318; */
  padding: 0% 0 2% 0;
}

.services_section_span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  text-transform: uppercase;

  /* Secondary */

  color: #b3881d;
}

.services_section_heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #2b2926;
}
.services_section_heading span {
  color: #02225e;
}
.services_section_text_p {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #919b9b;
  margin-bottom: 40px;
  width: 32%;
  /* background-color: #042b08; */
}
.LeftSectionHeader {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  align-items: left;
  margin: 2% 0% 3% 0%;
}
.SectionHeaderDivider {
  height: 3px;
  background-color: #42c2ff;
  border-radius: 4px;
  margin: 2% 0% !important;
}
.SectionHeaderDesp {
  font-size: 1rem !important;
  font-weight: 400 !important;
  opacity: 50%;
  width: 75%;
  margin-top: 1%;
}
.SectionHeaderMargin {
  height: 3px;
  background-color: #42c2ff;
  border-radius: 4px;
  margin: 1.5% 0% !important;
}
.SectionHeaderH1_WP {
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: #42c2ff;
}
.SectionHeaderH2 {
  font-size: 1.8rem !important;
  text-transform: capitalize;
  font-weight: 800 !important;
}
.SectionHeaderTypo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 5% 0 2% 0;
}
.SectionHeaderH1 {
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: #42c2ff;
}
.SectionHeaderDivider {
  height: 3px;
  background-color: #42c2ff;
  border-radius: 4px;
  margin: 2% 0% !important;
}
.SectionHeaderMargin {
  height: 3px;
  background-color: #42c2ff;
  border-radius: 4px;
  margin: 1.5% 0% !important;
}
.SectionHeaderH2 {
  font-size: 1.8rem !important;
  text-transform: capitalize;
  font-weight: 800 !important;
}
.SectionHeaderDesp {
  font-size: 1rem !important;
  font-weight: 400 !important;
  opacity: 50%;
  width: 75%;
  margin-top: 1%;
}
.clientsFeedBackSection {
  /* background-color: #919b9b; */
  height: 34rem;
}

.clientFeedBackHeadingtxt {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 100px;
  /* margin-bottom: 3%; */
  font-size: 30px;
  font-weight: 600;
  font-family: 'Poppins';
  line-height: 24px;
  margin-left: 10%;
}
.ReviewSliderContainer {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 2% 0;
  height: 50vh;
  width: 100%;
  align-items: center;
  margin-bottom: 320px;
  padding-bottom: 0px;
}
.noReview {
  text-align: center;
  opacity: 0.2;
  font-size: 20px;
  font-weight: 600;
  font-family: 'poppins';
  text-transform: capitalize;
}
.ReviewSectionMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ReviewCardSlider {
  position: relative;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin: 0;
  height: 38vh;
  width: 100%;
  align-items: center;
  padding-top: 2%;
}

.fade-left {
  position: absolute;
  left: 10%;
  top: 9%;
  height: 60%;
  width: 20%;
  z-index: 99;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0)
  );
}
.ReviewSection_Carousel {
  overflow-x: scroll;
}
.CarouselLeftArrow {
  position: absolute !important;
  top: 30% !important;
  left: 5% !important;
  z-index: 1000 !important;
  color: #003b7e;
  background: #f8f8f8ba !important;
  box-shadow: none !important;
  transition: 0.5s;
}
.CarouselLeftArrow:hover {
  /* background: #003b7eba !important; */
  color: #f8f8f8 !important;
}
.CarouselRightArrow {
  position: absolute !important;
  top: 30% !important;
  right: 5% !important;
  color: #003b7e;
  background: #f8f8f8aa !important;
  box-shadow: none !important;
  transition: 0.5s;
}
.fade-right {
  position: absolute;
  right: 10%;
  top: 9%;
  height: 60%;
  width: 20%;
  z-index: 99;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0)
  );
}
.CarouselRightArrow:hover {
  background: #f8f8f8 !important;
  color: #f8f8f8 !important;
}
.ReviewCard_Container {
  user-select: none;
  max-width: 400px;
  min-width: 200px;
  height: 560px;
  border-radius: 15;
  /* background-color: #003b7e; */
  margin-bottom: 50%;
  padding-top: 50px;
  opacity: 1 !important;
}
.ReviewCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 7% 3%;
  border: 1px solid #c4c0c0;
  border-radius: 5%;
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(93, 96, 104, 0.4);
  height: 58%;
  width: 100%;
  transition: 0.5s ease-in-out;
  transform: scale(0.9);
  filter: grayscale(0.5);
  opacity: 1;
  color: #000;
}
.Selection::selection {
  background: transparent;
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .services_section_text_p {
    width: 52%;
  }
  .services_section_bg img {
    width: 70%;
  }
  .clientFeedBackHeadingtxt {
    margin-bottom: 140px;
    font-size: 28px;
    font-weight: 600;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1199px) {
  .services_section_text_p {
    width: 52%;
  }
  .services_section_bg img {
    padding: 5% 25% 0 0;
    width: 100%;
  }
  .clientFeedBackHeadingtxt {
    margin-bottom: 140px;
    font-size: 26px;
    font-weight: 600;
  }
}
/* MEDIA QUERY FOR LARGE DEVICES */
@media screen and (min-width: 320px) and (max-width: 500px) {
  .services_section_main {
    padding: 0;
  }
  .services_section {
    width: 100%;
    margin: 0%;
    padding: 0 5%;
  }
  .services_section p {
    font-size: 15px;
  }
  .services_section_bg_img {
    margin-left: 10%;
    display: none;
  }
  #h1tag {
    font-size: 1.4rem !important;
  }
  #h2tag {
    font-size: 1.1rem !important;
  }
  #h3tag {
    font-size: 0.9rem !important;
  }
  .clientFeedBackHeadingtxt {
    margin-bottom: 120px !important;
    padding-top: 20px;
    font-size: 24px;
    font-weight: 600;
  }
  .ReviewCard {
    width: 100%;
    height: 66%;
    margin-top: 2%;
  }
  .services_section_text_p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #919b9b;
    margin-bottom: 40px;

    width: 100%;
  }
  .services_section_heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #2b2926;
    padding: 0 0 5% 0;
  }
  .services_section_heading span {
    color: #02225e;
  }
  #demo-simple-select {
    padding: 7px !important;
    padding-left: 14px !important;
  }
  .services_section_span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;

    text-transform: uppercase;

    /* Secondary */

    color: #b3881d;
  }
}
@media screen and (min-width: 501px) and (max-width: 1000px) {
  .services_section_main {
    padding: 0;
  }
  .services_section {
    width: 100%;
    margin: 0%;
    padding: 0 5%;
  }
  .services_section p {
    font-size: 15px;
  }
  .services_section_bg_img {
    margin-left: 10%;
  }

  .services_section_heading {
    font-size: larger;
    margin: 0;
    padding: 0;
  }
  .services_section_text_p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #919b9b;
    margin-bottom: 40px;

    width: 70%;
  }
  .services_section_bg_img {
    display: none;
  }
}
@media screen and (max-width: 990px) {
  .clientFeedBackHeadingtxt {
    margin-bottom: 130px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1550px) {
  .ReviewCardSlider {
    height: 40vh;
  }
}
/* @media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .ReviewCardSlider {
    height: 42vh;
  }
} */
@media only screen and (max-width: 1621px) {
  .ReviewSliderContainer {
    /* margin: 10% 0; */
  }
}
