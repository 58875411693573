.footer {
  width: 100%;
  padding: 2% 10%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.footer_top {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  text-align: center;
}

.footer_top_center > ul {
  display: flex;
  list-style: none;
  display: flex;
  /* padding-left: 5px; */
}

.footer_top_center ul a {
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
  color: #2b2926;
  margin: 0 50px;
}
.social_media {
  text-decoration: none;
  font-size: 22px;
  font-weight: 600;
  color: #2b2926;
  margin-top: 30px;
}

.footer_middle {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.footer_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.footer_text_p {
  font-size: 18px;
  font-weight: 500;
  color: #919b9b;
  font-family: 'Raleway', 'Josefin Sans', sans-serif;
}
.translate_btn {
  background: #02225e;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}
.footer_span2 {
  font-size: 18px;
  color: #2b2926;
}

.footer_img {
  display: inline;
}
.lo {
  display: inline;
  margin-left: -40px;
}
.footer_top_center_outer {
  width: 70%;
  display: flex;
  margin: auto;
}
.footer_middle_outer {
  width: 50%;
}

.footer_top {
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}

.footer_bottom {
  text-align: center;
}
.footer_top_left {
  text-align: center;
}
.lo {
  display: none;
}
.footer_img {
  display: flex !important;
  align-items: center !important;
  background-color: black;
  padding: 5px;
  border-radius: 7px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  display: block;
}
.footer_img2 {
  margin-left: 50px;
}

.footer_span {
  display: flex;
}
a {
  text-decoration: none;
}
.footer_middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo_footer img {
  /* background-color: aqua; */
  width: 330px;
}
.footer_top_right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_top_center_outer {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.footer_middle_outer {
  width: 100%;
}
.footer_top_center {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 4% !important;
}
@media screen and (max-width: 1580px) {
  .logo_footer img {
    width: 210px;
  }

  .footer_top_center ul a {
    font-size: 18px;
  }
  .social_media {
    font-size: 18px;
  }
}
@media screen and (max-width: 1280px) {
  .footer_top_center ul a,
  .social_media {
    font-size: 20px;
    text-align: center;
    display: block;
  }
  .logo_footer img {
    width: 230px;
  }
  .footer_span2 {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 992px) {
  .footer_top_center ul a,
  .social_media {
    padding: 5px 0;
    font-size: 16px;
  }
  .footer_top_center ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 0;
  }
}
@media screen and (max-width: 600px) {
  .footer_top_left {
    text-align: center;
  }
  .footer_top_center ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer_top_center_outer {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
  }
  .footer_top_center ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* background-color: aliceblue; */
  }
  .footer_top_center ul a,
  .social_media {
    font-size: 16px;
    padding: 2px 0;
  }

  .footer_middle_outer {
    width: 100%;
  }
  .footer_middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .logo_footer img {
    /* background-color: aqua; */
    width: 200px;
  }
  .footer_top_right {
    display: flex;
    justify-content: center;
  }
  .footer {
    gap: inherit;
    padding: 2% 2%;
  }
  .footer_span2 {
    font-size: 16px !important;
  }
}
