.client_testimonials {
  width: 100%;
  position: relative;
}
.arrowButton {
  background: transparent;
  border: none;
}
.arrowBtn {
  margin: 14px auto;
}
.testimonials_heading {
  margin-top: 2%;
  margin-left: 10%;
}
.testimonials {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.testimonials_card_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../../assets/home/testimonials-section.png');
  position: relative;
}

.testimonials_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  gap: 30px;
  text-align: center;
  width: 36%;
  margin-right: 6%;
  background: #fdfcfc;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  transition: ease 1000ms;
  margin: 0 auto;
}

.card_description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #6a6a6a;
}
.card_description button {
  border: none;
  padding: 0.5% 1%;
  outline: none;
}
.profile {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.profile_img {
  width: 50px;
  height: 50px;
  border: 2px solid #02225e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile_info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}
.name {
  font-weight: 600;
  font-size: 18px;
  color: #2b2926;
}
.designation {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #02225e;
}
@media screen and (max-width: 1550px) {
  .testimonials_card {
    width: 50%;
  }
  .profile_info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -7%;
  }
}
@media screen and (max-width: 1130px) {
  .testimonials_card {
    width: 70%;
  }
}
@media only screen and (min-width: 501px) and (max-width: 1000px) {
  .client_testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    margin: 0;
  }
  .testimonials_heading {
    width: 100%;
    text-align: center;
    font-size: 24px;
    padding: 0;
    margin: 0;
  }
  .testimonials {
    display: flex;
    height: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .testimonials_card {
    padding: 2%;
    margin: 0;
    display: flex;
    width: 80%;
  }
  .card_description {
    padding: 2%;
    width: 100%;
    font-size: 16px;
    line-height: normal;
  }
  .testimonials_card_container {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profile_info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10%;
    margin-bottom: 5%;
  }
}
@media screen and (min-width: 320px) and (max-width: 500px) {
  .client_testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10% 0 5% 0;
    margin: 0;
  }
  .testimonials_heading {
    width: 100%;
    text-align: center;
    font-size: 20px;
    padding: 0;
    margin: 0;
  }
  .testimonials {
    display: flex;
    height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .testimonials_card {
    padding: 2%;
    margin: 0;
    display: flex;
    width: 90%;
  }
  .card_description {
    padding: 2%;
    width: 100%;
    line-height: normal;
    font-size: smaller;
  }
  .testimonials_card_container {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profile_info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10%;
    margin-bottom: 5%;
  }
  .arrowButton img {
    width: 30px !important;
    color: #02225e !important;
  }
}
