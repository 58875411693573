.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  background: #fff;
  padding: 0% 10%;
}
.header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 0%;
}
.header_top_left {
  display: flex;
}
.header_bottom_left p {
  text-align: center;
  font-weight: 700;
  color: #02225e;
  margin-top: -7px;
}
.header_top_left p {
  align-self: center;
  font-size: 14px;
}
.header_top_left span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.header_top_right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.header_top_span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #2b2926;
}
.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.header_top_span:hover {
  color: red;
}
.header_bottom {
  display: flex;
  justify-content: space-between;
  padding: 1% 0%;
  align-items: center;
}
.menu-icon {
  display: none;
}
.header_bottom_center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
.header_bottom_center ul {
  display: flex;
  list-style: none;
  gap: 40px;
}
.header_bottom_center_button {
  display: none;
}
.header_bottom_center ul a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #6a6a6a;
  margin-right: 20px;
}
.header_bottom_center ul li {
  gap: 20px !important;
}
.header_bottom_center ul li a:hover {
  color: #b3881d;
}
.header_bottom_search {
  display: flex;
  align-items: center;
  background: rgba(66, 85, 255, 0.1);
  padding: 17px 32px;
  border-radius: 100px;
  cursor: text;
}
.header_bottom_search input {
  outline: none;
  border: none;
  background: transparent;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
  color: #02225e;
}
.header_bottom_search input::placeholder {
  font-size: 16px;
  font-weight: 700;
  color: #02225e;
}
.trans_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  outline: none;
  cursor: pointer;
  padding: 5px 20px;
  border-radius: 20px;
  color: rgb(0, 59, 126);
  font-size: 20px;
  border: none;
  font-weight: 600;
}
.trans_btn img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border-radius: 20px;
}
/* cookies */
.CookieBanner_desp {
  color: #000000;
  font-weight: 500 !important;
  font-size: 1.3rem !important;
  line-height: 1 !important;
}
.CookieBanner_Container {
  font-family: 'Poppines';
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  padding: 3% 5%;
  z-index: 10000;
}
.CookieBanner_Div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CookieBanner_TypoDiv {
  width: 40%;
  padding: 5px;
}
.CookieBanner_Link {
  color: #003b7e;
  text-decoration-line: underline;
  font-size: 1.2rem !important;
}
.CookieBanner_BtnDiv {
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 3%;
}
.CookieBanner_AgreeBtn {
  /* margin-right: 2% !important; */
  width: 50%;
  padding: 5.5% !important;
  background: #003b7e !important;
  color: white !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  border-radius: 10px !important;
  cursor: pointer;
}
.CookieBanner_DeclineBtn {
  padding: 5% !important;
  width: 50%;
  margin-right: 2% !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  border: 3px solid #003b7e !important;
  color: #003b7e !important;
  border-radius: 10px !important;
  cursor: pointer;
}

@media screen and (min-width: 1000px) and (max-width: 1600px) {
  .logo img {
    width: 210px;
  }
  .header_bottom_left p {
    width: 210px;
    text-align: center;
  }
  .trans_btn {
    padding: 3px 15px;
  }
}
@media screen and (max-width: 1000px) {
  .header {
    padding: 0% 5%;
  }
  .header_top {
    margin-top: 5px;
  }

  .header_bottom {
    padding: 5px 0;
  }

  .logo img {
    width: 210px;
  }
  .header_bottom_center {
    display: flex;
    align-items: flex-start;
  }
  .header_bottom_center {
    position: absolute;
    right: 0;
    top: 120px;
    background-color: #02225e;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 1;
  }
  .header_bottom_center.active {
    display: flex;
    justify-content: center;
    width: 270px;
    border-radius: 10px;
  }
  .header_bottom_center ul {
    width: 90%;
    flex-direction: column;
    margin-top: 10%;
    align-items: center;
  }
  .header_bottom_center ul a {
    font-size: 16px;
    font-weight: 400;
    color: white;
  }
  .header_bottom_center a.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
  }
  .header_bottom_center ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
  }
  .menu-icon {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 5%;
  }
  .trans_btn {
    position: relative;
    padding: 0.1px 2%;
    right: 10%;
    top: -5px;
    font-size: 16px;
  }
  .trans_btn img {
    width: 18px;
    height: 18px;
  }
  .header_bottom_search {
    display: none;
  }
}

@media screen and (max-width: 607px) {
  .CookieBanner_Div {
    display: block;
  }
  .CookieBanner_TypoDiv {
    width: 100%;
  }
  .CookieBanner_BtnDiv {
    width: 100%;
  }
  .CookieBanner_AgreeBtn {
    margin: 5px !important;
    width: 50%;
    padding: 4% !important;
  }
  .CookieBanner_DeclineBtn {
    margin: 5px !important;
    padding: 4% !important;
    width: 50%;
  }
  .CookieBanner_desp {
    font-size: 1rem !important;
  }
  .CookieBanner_Link {
    font-size: 1rem !important;
  }
  .header_top_left {
    width: 0;
  }
  .header_top_left p {
    font-size: 10px;
  }
  .header_top_left span {
    font-size: smaller;
  }
  .header_top_span {
    font-size: smaller;
  }
  .header_top_right {
    width: 100%;
    justify-content: flex-end;
  }
  .icons img {
    height: 40px;
  }
  .menu-icon {
    right: 5%;
  }
  .header_bottom_left P {
    text-align: center;
    font-size: small;
    font-weight: 700;
    color: #02225e;
  }
  .logo img {
    width: 150px;
  }
  .header_bottom_left p {
    width: 150px;
    text-align: center;
  }
  .trans_btn{
    padding: 5px 10px;
    margin-right: 30px;
    margin-top: 10%;
  }
}

@media screen and (max-width: 400px) {
  .header_top {
    padding: 0%;
    margin-top: 0;
  }
  .header_top_left {
    display: flex;
    flex-direction: column;
  }
  .header_top_left p {
    margin-right: 15%;
  }
  .header_top_left span {
    font-size: 12px;
  }
  .header_top_right {
    gap: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .header_top_span {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .icons {
    margin-left: auto;
  }

  .header_bottom_center ul a {
    font-size: smaller;
  }
}
