.laser_outer_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10%;
  padding-top: 90px;
}
.laser_hero_section_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.preOwnTxtandIconRowDiv {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}
.preOwnIconCotainerDiv {
  margin-right: 12px;
  /* padding-right: 3%; */
  margin-left: 12px;
  width: 67px;
  /* background-color: #02225e; */
}
.preOwnIconCotainerDiv img {
  width: 100%;
}
.laser_hero_section_heading_text {
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: #b3881d;
  order: 0;
}
.laser_hero_section_heading {
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #454238;
  order: 1;
  margin-bottom: 20px;

}
.laser_hero_section_heading_eng {
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #454238;
  order: 1;
  margin-bottom: 20px;

}
.laser_hero_section_text_paragraph {
  width: 75%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
  color: #919b9b;
  /* background-color: #02225e; */
  order: 2;
  margin-bottom: 2%;
}
.laser_search_button_div {
  display: flex;
  align-items: center;
  padding: 0.1% 0.5% 0.1% 2%;
  border-radius: 100px;
  height: 100%;
  background: #fdfcfc;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  margin: 2% 0 2% 0;
  width: 23%;
}

.laser_search_icon {
  display: flex;
  width: 70%;
}
.search_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  color: #6a6a6a;
}
.laser_hero_section_header_search_input {
  display: flex;
  align-items: center;
  width: 80%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #6a6a6a;
}
.laser_hero_section_header_search_input input {
  width: 100%;
  border: none;
  padding: 8%;
  outline: none;
}
.laser_button_div {
  display: flex;
  width: 30%;
  justify-self: flex-end;
}
.laser_hero_section_header_search_button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'DM Sans';
  width: 100%;
  height: 48px;
  border: none;
  background: #02225e;
  border-radius: 100px;
  color: #ffffff;
}
.LaserInputSearch_component {
  width: 30%;
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .laser_outer_main {
    width: 100%;
    padding-top: 0px;
  }
  .preOwnTxtandIconRowDiv {
     
    width: 90%;
    margin: auto;
  }
  .preOwnIconCotainerDiv {
    margin-right: -50px;
    margin-left: 25px;
    width: 50px;
  }

  .laser_hero_section_heading_text {
    font-size: medium;
    width: 100%;
  }
  .laser_hero_section_heading {
    width: 97%;
    /* background-color: #919b9b; */
    line-height: normal;
    font-size: 24px;
    padding-left: 4%;

  }
  .laser_hero_section_heading_eng {
    width: 97%;
    /* background-color: #919b9b; */
    line-height: normal;
    font-size: 24px;
    padding-left: 11%;

  }
  .laser_hero_section_text_paragraph {
    padding: 0 5%;
    width: 100%;
    text-align: justify;
    font-size: smaller;
    line-height: normal;
    /* background-color: #02225e; */
  }
  .LaserInputSearch_component {
    width: 100%;
    padding: 0 5%;
  }
}
@media only screen and (min-width: 501px) and (max-width: 1000px) {
  .laser_outer_main {
    width: 100%;
    padding-top: 100px;
  }

  .laser_hero_section_heading_text {
    font-size: medium;
    width: 100%;
  }
  .laser_hero_section_heading {
    width: 90%;
    line-height: normal;
    /* background-color: #919b9b; */
    font-size: 35px;
  }
  .laser_hero_section_text_paragraph {
    padding: 0 5%;
    width: 80%;

    font-size: smaller;
    line-height: normal;
    /* background-color: #02225e; */
  }
  .LaserInputSearch_component {
    width: 60%;
    padding: 0 5%;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1600px) {
  .laser_outer_main {
    width: 100%;
    /* background-color: #6a6a6a; */
  }

  .laser_hero_section_heading_text {
    font-size: medium;
    width: 100%;
  }
  .laser_hero_section_heading {
    width: 100%;
    /* background-color: #919b9b; */
    font-size: 33px;
  }
  .laser_hero_section_text_paragraph {
    padding: 0 5%;
    width: 80%;

    font-size: 18px;
    line-height: normal;
    /* background-color: #02225e; */
  }
  .LaserInputSearch_component {
    width: 40%;
    padding: 0 5%;
  }
}
