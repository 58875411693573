.notices {
  width: 80%;
  margin: 5% auto;
}
.Notices_head {
  text-align: center;
}
.Notices_title {
  font-weight: 600;
  color: #02225e;
  font-size: 40px;
}
.Notices_title_p {
  font-size: 18px;
}
.notices_p,
.art_p {
  font-size: 18px;
  padding-top: 30px;
}
.article_div {
  padding: 30px 0;
}
.art_title {
  font-weight: 600;
  color: #02225e;
  font-size: 30px;
}

@media only screen and (max-width: 768px) {
  .Notices_title {
    font-size: 30px;
  }
  .Notices_title_p {
    font-size: 20px;
  }
  .art_title {
    font-size: 22px;
  }
  .notices_p,
  .art_p {
    font-size: 16px;
  }
  .notices_p{
    padding-top: 2%;
    font-size: 15px;
    text-align: justify;
  }
}
