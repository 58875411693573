.home_main {
  padding-top: 180px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.heroWrapDiv {
  background-color: #b3881d;
  display: flex;
  flex-direction: row;
}
.home_main_inner {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
  /* background-color: #898b8f; */
  position: relative;
  /* background-color: #41392b; */
}
.home_left {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.home_left_inner {
  width: 75%;
}
.home_span_text {
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-transform: uppercase;
  color: #b3881d;
}
.home_heading {
  width: 70%;
  color: #41392b;
}
.home_heading h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  color: #02225e;
}
/* .home_heading span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 60px;
  color: #02225e;
} */
.heroBG {
  width: 100%;
}
.home_text_pi {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #919b9b;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
}
.home_right {
  width: 100%;
  height: 45rem;
  /* background-color: #c59541; */
}
.home_right img {
  width: 100%;
  height: 100%;
  /* margin-right: 60%; */
  position: absolute;
  right: 0%;
  /* left: 30%; */
}
.input_component {
  width: 50%;
}
@media only screen and (max-width: 1621px) {
  .home_main {
    padding-top: 150px;
  }
  .home_main_inner {
    margin: 0;
    padding: 0;
  }
  .home_left_inner {
    width: 77%;
  }
  .home_span_text {
    font-size: medium;
  }
  .home_heading {
    width: 100%;
  }
  .home_text_pi {
    font-size: 20px;
    line-height: 28px;
  }
  .heroBG {
    width: 88%;
  }
  .input_component {
    width: 60%;
  }
}
@media only screen and (max-width: 1070px) {
  .home_left_inner {
    margin-top: 15%;
    width: 100%;
  }
  .home_heading {
    width: 100%;
  }
  .home_text_pi {
    /* width: 100%; */
    font-size: 20px;
    top: 53%;
    /* line-height: 28px; */
    /* padding-right: 18%; */
  }
  .input_component {
    display: flex;
    justify-content: center;
    width: 90%;
  }
}
@media only screen and (min-width: 651px) and (max-width: 1000px) {
  .home_main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
  }
  .home_main_inner {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding-top: 0%;
    width: 100%;
  }
  .home_left {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home_left_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .home_span_text {
    display: flex;
    justify-content: center;
    width: 70%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    text-transform: uppercase;
    color: #b3881d;
  }
  .home_heading {
    text-align: center;
    width: 70%;
    font-size: 25px;
    color: #41392b;
  }
  .home_heading span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 20px;
    color: #02225e;
  }
  .home_text_pi {
    font-weight: 400;
    font-size: 17px;
    top: 57%;
  }
  .home_right {
    width: 100%;
    /* background-color: #919b9b; */
  }
  .home_right img {
    width: 100% !important;
  }
  .input_component {
    width: 60%;
  }
}
@media only screen and (min-width: 501px) and (max-width: 650px) {
  .home_main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }
  .home_main_inner {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    width: 100%;
  }
  .home_left {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home_left_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .home_span_text {
    display: flex;
    justify-content: center;
    width: 70%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    text-transform: uppercase;
    color: #b3881d;
  }
  .home_heading {
    text-align: center;
    width: 70%;
    font-size: 25px;
    color: #41392b;
  }
  .home_heading span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    color: #02225e;
  }
  .home_text_pi {
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    top: 58%;
  }
  .home_right {
    width: 100%;
  }
  .home_right img {
    width: 100%;
  }
  .input_component {
    width: 60%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .home_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
  }
  .home_main_inner {
    width: 100%;
    flex-direction: column-reverse;
  }
  .home_left {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home_left_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home_span_text {
    width: 100%;
    font-size: 18px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .home_heading {
    font-size: 16px;
    text-align: left;
    width: 90%;
  }
  .home_text_pi {
    line-height: 25px;
    font-size: 15px;
    width: 85%;
    text-align: center;
    top: 55%;
  }
  .home_heading span {
    font-size: 24px;
    line-height: 20px;
    color: #02225e;
  }
  .home_right {
    width: 70%;
  }
  .input_component {
    width: 80%;
  }
}
@media only screen and (min-width: 390px) and (max-width: 844px) {
  .home_right {
    width: 100vw;
  }
  .home_right img {
    height: 50rem;
  }
}
