.contact {
  height: 100%;
  width: 100%;
  background: #02225e;
  overflow-x: hidden;
}

.contact_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact_top_heading {
  font-size: 36px;
  display: flex;
  gap: 10px;
  font-weight: 600;
  margin-top: 50px;
  color: #fdfcfc;
}

.contact_top_text_p {
  width: 50%;
  font-size: 20px;
  color: #fdfcfc;
  text-align: center;
  margin: 10px 0px;
  line-height: 26px;
  /* background-color: #fdfcfc; */
}

.contact_bottom {
  display: flex;
  padding: 4% 10%;
  margin-left: 17%;
  margin-right: 17%;
  gap: 20px;
}

.contact_bottom_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 30%;
}

.contact_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.contact_icon {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.contact_info_text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contact_info {
  font-weight: 600;
  font-size: 15px;
  color: #fdfcfc;
}

.phone {
  font-weight: 600;
  font-size: 16px;
  color: #fdfcfc;
}

.contact_bottom_right {
  width: 100%;
  flex: 2;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline::placeholder {
  color: #fff !important;
}

.contact_bottom_right_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6% 0;
}

.attachment {
  position: relative;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.hide_file {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  font-size: 24px;
  padding: 20px;
}

.contact-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 32px;
  width: 25%;
  height: 58px;
  border: none;
  color: #02225e;
  background: #fdfcfc;
  border-radius: 100px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
textarea {
  background-color: transparent;
  outline: none;
  border-radius: 10px;
  height: 100px;
  color: white;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid white;
  padding: 2% 2% 2% 2%;
  resize: none;
  height: 148px;
  /* overflow: hidden; */
}
textarea::-webkit-input-placeholder {
  color: #fff;
}
textarea::-webkit-scrollbar {
  background: #02225e;
  padding: 2%;
  border-radius: 12px;
  width: 7px;
  height: 14px;
}
textarea::-webkit-scrollbar-button {
  overflow: hidden;
  border-radius: 12px;
  display: none;
  /* background-color: rgb(82, 160, 160); */
}
textarea::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgb(202, 197, 167);
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .contact {
    margin-top: 5%;
    width: 100%;
    background: #02225e;
    overflow-x: hidden;
    padding-bottom: 10%;
  }

  .contact_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact_top_heading {
    font-size: 36px;
    display: flex;
    gap: 10px;
    font-weight: 600;
    margin-top: 50px;
    color: #fdfcfc;
  }

  .contact_top_text_p {
    font-size: 14px;
    color: #fdfcfc;
    line-height: 26px;
    width: 85%;
  }

  .contact_bottom {
    display: flex;
    padding: 4% 0%;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    flex-direction: column-reverse;
  }

  .contact_bottom_left {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-self: center;
    flex: 1;
    gap: 20px;
    display: none;
    /* background-color: #cf8888; */
  }

  .contact_icons {
    display: flex;
    align-items: center;
    width: 100%;

    /* background-color: #5e3a3a; */
  }

  .contact_icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
  }
  .contact_icon_img {
    width: 45%;
  }
  .contact_info_text {
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 5px;
  }

  .contact_info {
    font-weight: 600;
    font-size: 15px;
    color: #fdfcfc;
  }

  .phone {
    font-weight: 600;
    font-size: 15px;
    color: #fdfcfc;
  }

  .contact_bottom_right {
    width: 100%;
    flex: 2;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline::placeholder {
    color: #fff !important;
  }

  .contact_bottom_right_btn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5% 0 5% 0;
    /* background-color: #fdfcfc; */
  }

  .attachment {
    position: relative;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    background-color: #02225e;
  }

  .hide_file {
    background-color: #fff;
    position: absolute;
    z-index: 1000;
    opacity: 0;
    width: 100%;
    cursor: pointer;
    font-size: 24px;
    padding: 20px;
  }

  .contact-btn {
    width: 50%;
    height: 38px;
    margin-top: 10%;
  }
}
@media only screen and (min-width: 501px) and (max-width: 1000px) {
  .contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10%;
  }
  .contact_top_text_p {
    font-size: 14px;
    line-height: 26px;
    width: 75%;
  }

  .contact_bottom {
    width: 80%;
    display: flex;
    padding: 4% 0%;
    display: flex;
    flex-direction: column-reverse;
  }

  .contact_bottom_left {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    flex: 1;
    display: none;
  }

  .contact_icons {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .contact_icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
  }
  .contact_icon_img {
    width: 45%;
  }
  .contact_info_text {
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 5px;
  }

  .contact_info {
    font-weight: 600;
    font-size: 15px;
    color: #fdfcfc;
  }

  .phone {
    font-weight: 600;
    font-size: 15px;
    color: #fdfcfc;
  }

  .contact_bottom_right {
    width: 100%;
    flex: 2;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline::placeholder {
    color: #fff !important;
  }

  .contact_bottom_right_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5% 0 5% 0;
  }
  .contact_bottom_right_btn button {
    width: 30%;
  }
  .attachment {
    position: relative;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .hide_file {
    background-color: #fff;
    position: absolute;
    z-index: 1000;
    opacity: 0;
    width: 100%;
    cursor: pointer;
    font-size: 24px;
    padding: 20px;
  }

  .contact-btn {
    width: 30% !important;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1400px) {
  .contact_bottom_left {
    justify-content: space-evenly;
    height: 300px;
  }
  .contact-btn {
    width: 20%;
  }

  .contact_icon {
    height: 50px;
    width: 50px;
    /* background-color: #b3c3e0; */
  }
  .contact_icon_img {
    height: 30px;
    width: 30px;
    /* background-color: blueviolet; */
  }
  .contact_top_text_p {
    font-size: 14px;
    color: #fdfcfc;
    line-height: 26px;
    width: 32%;
  }
}
